import React from "react"

import { AccordionGroup, RichText, Wrapper } from "components"
import css from "@styled-system/css"

const FAQSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {/* {data.faqs && (
        <Wrapper id={id}>
          <AccordionGroup items={data.faqs} />
        </Wrapper>
      )} */}
      {data.faqCategories && (
        <Wrapper id={id}>
          <AccordionGroup
            items={data.faqCategories.map(category => ({
              heading: category.primary.category.text,
              children: (
                <AccordionGroup
                  items={category.items.map(({ question, answer }) => ({
                    heading: question,
                    children: (
                      <RichText
                        size={400}
                        content={{
                          html: answer && answer.html,
                        }}
                      />
                    ),
                  }))}
                />
              ),
            }))}
            css={css({
              '[data-accordion-component="AccordionItemPanel"]': {
                pl: "layout.4",
                '[data-accordion-component="AccordionItemHeading"]': {
                  '[data-component-id="typography.heading"]': {
                    fontSize: ["16px", "20px"],
                    lineHeight: ["20px", "24px"],
                  },
                  ".control": {
                    width: ["20px", "24px"],
                    height: ["20px", "24px"],
                    mt: 0,
                    opacity: 0.25,
                  },
                },
                '[data-accordion-component="AccordionItem"]': {
                  py: ["layout.2", "layout.3"],
                  ":last-of-type": {
                    pb: 0,
                    borderBottom: 0,
                  },
                },
              },
            })}
          />
        </Wrapper>
      )}
    </>
  )
}

export default FAQSection
