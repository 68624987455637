import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { NextPage, RichText, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import FAQSection from "./_faqSection"

function FAQPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicFaqs {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          page_title {
            text
          }
          questions {
            question
            answer {
              html
            }
          }
          next_page_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_title {
            text
          }
          next_page_button_text
          next_page_button_link {
            slug
          }
          body {
            ... on PrismicFaqsBodyFaqCategory {
              id
              primary {
                category {
                  text
                }
              }
              items {
                question
                answer {
                  html
                }
              }
            }
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })

  return (
    <>
      <SEO
        title={
          finalData.prismicFaqs.data.seo_title &&
          finalData.prismicFaqs.data.seo_title.text
        }
        description={
          finalData.prismicFaqs.data.seo_description &&
          finalData.prismicFaqs.data.seo_description.text
        }
        image={
          finalData.prismicFaqs.data.seo_image &&
          finalData.prismicFaqs.data.seo_image.url
        }
        imageAlt={
          finalData.prismicFaqs.data.seo_image &&
          finalData.prismicFaqs.data.seo_image.alt
        }
      />

      {/* ============ INTRO ============ */}
      <IntroSection
        id="faq-intro"
        data={{
          title:
            finalData.prismicFaqs.data.page_title &&
            finalData.prismicFaqs.data.page_title.text,
        }}
      />

      {/* ============ FAQs ============ */}
      {finalData.prismicFaqs.data.questions && (
        <FAQSection
          id="faq-faqs"
          data={{
            faqs: finalData.prismicFaqs.data.questions.map(
              ({ question, answer }) => ({
                heading: question,
                children: (
                  <RichText
                    content={{
                      html: answer && answer.html,
                    }}
                  />
                ),
              })
            ),
            faqCategories: finalData.prismicFaqs.data.body,
          }}
        />
      )}

      {/* ============ NEXT PAGE ============ */}
      <NextPage
        id="faq-nextPage"
        image={finalData.prismicFaqs.data.next_page_image}
        title={
          finalData.prismicFaqs.data.next_page_title &&
          finalData.prismicFaqs.data.next_page_title.text
        }
        buttonLabel={finalData.prismicFaqs.data.next_page_button_text}
        buttonTo={
          "/" + finalData.prismicFaqs.data.next_page_button_link.slug + "/"
        }
      />
    </>
  )
}

export default FAQPage
